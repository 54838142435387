
import { defineComponent } from 'vue'
import { clearAuthTokens, isLoggedIn } from 'axios-jwt'
import { apiClient } from '@/api_client'
const proto = 'https'

export default defineComponent({
  name: 'HelloWorld',

  data() {
    const signedIn = isLoggedIn()
    return {
      appName: '',
      appVersion: '',
      apiDocsUrl: `${proto}://${window.location.hostname}:${window.location.port}/apidocs`,
      signedIn,
      myUserData: null
    }
  },

  methods: {
    signOut() {
      clearAuthTokens()
      this.signedIn = false
    }
  },

  async mounted() {
    this.signedIn = isLoggedIn()
    const appData = await apiClient.get('/')
    this.appName = appData.data.name
    this.appVersion = appData.data.version
    if (this.signedIn) {
      const me = await apiClient.get('/users/me')
      this.myUserData = me.data
    }
  }
})
