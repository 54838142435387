import axios from 'axios'
import { IAuthTokens, TokenRefreshRequest, applyAuthTokenInterceptor } from 'axios-jwt'
import { set } from 'lodash'

const baseURL = `${window.location.protocol}//${window.location.hostname}:${window.location.port}`

// 1. Create an axios instance that you wish to apply the interceptor to
export const apiClient = axios.create({ baseURL })

// 2. Define token refresh function.
const requestRefresh: TokenRefreshRequest = async (refreshToken: string): Promise<IAuthTokens | string> => {
  // Important! Do NOT use the axios instance that you supplied to applyAuthTokenInterceptor (in our case 'apiClient')
  // because this will result in an infinite loop when trying to refresh the token.
  // Use the global axios client or a different instance
  const { data } = await axios.post(`${baseURL}/oauth/refresh-token`, { refreshToken })

  // If your backend supports rotating refresh tokens, you may also choose to return an object containing both tokens:
  return {
    accessToken: data.accessToken,
    refreshToken: data.refreshToken
  }
  // return data.accessToken
}

// 3. Add interceptor to your axios instance
applyAuthTokenInterceptor(apiClient, { requestRefresh })

export function getErrorMessage(error: any): string {
  const toLog = { config: error.config }
  let message

  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    set(toLog, 'response.data', error.response.data)
    set(toLog, 'response.status', error.response.status)
    set(toLog, 'response.headers', error.response.headers)
    if (error.response.data.message) {
      message = error.response.data.message
    } else {
      message = JSON.stringify(error.response.data)
    }
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    set(toLog, 'request', error.request)
    message = 'No response'
  } else {
    // Something happened in setting up the request that triggered an Error
    set(toLog, 'message', error.message)
    message = error.message
  }

  console.log('got error', toLog)
  return message
}
